body {
  background-color: #282c34;
  color: white;
}

.App-header {
  font-size: calc(10px + 2vmin);
  padding: 5px;
}

.player-name {
  text-transform: capitalize;
  cursor: pointer;
}

.player-name > a {
  text-decoration: none;
}

.player-name:hover {
  text-decoration: underline;
}

.topArea {
  display: flex;
  
}

.recent-list {
  margin-left: 20px;
}

.App-link {
  color: #61dafb;
}

.death_knight, .death_knight > a {
  color: #C41E3A;
}

.demon_hunter, .demon_hunter > a {
  color: #A330C9;
}

.druid, .druid > a {
  color: #FF7C0A;
}

.evoker, .evoker > a {
  color: #33937F;
}

.hunter, .hunter > a {
  color: #AAD372;
}

.mage, .mage > a {
  color: #3FC7EB;
}

.monk, .monk > a {
  color: #00FF98;
}

.paladin, .paladin > a {
  color: #F48CBA;
}

.priest, .priest > a {
  color: #FFFFFF;
}

.rogue, .rogue > a {
  color: #FFF468;
}

.shaman, .shaman > a {
  color: #0070DD;
}

.warlock, .warlock > a {
  color: #8788EE;
}

.warrior, .warrior > a {
  color: #C69B6D;
}
